class sysJwt {
  static getUserId() {
    return this.getUserInfo()["117115101114105100"];
  }

  static getUsername() {
    return this.getUserInfo()["11711510111411097109101"];
  }

  static getRoleId() {
    return this.getUserInfo()["114111108101105100"];
  }

  static getClaimsCacheId() {
    return this.getUserInfo()["999799104101105100"];
  }

  static getIsDefaultPwd() {
    if (this.getUserInfo()["100102112"]) {
      return this.getUserInfo()["100102112"];
    } else {
      return "";
    }
  }

  static getUserInfo = () => {
    try {
      return JSON.parse(atob(sessionStorage.getItem("748784").split(".")[1]));
    } catch (ex) {
      sessionStorage.removeItem("748784");
      window.location.replace("/login");
    }
  };

  static isAuthorized(toBeAuthorizedClaims) {
    var isAuthorized = true;

    let claims = this.getUserInfo()["9910897105109115"].split(",");

    for (var i = 0; i < toBeAuthorizedClaims.length; i++) {
      if (claims.includes(toBeAuthorizedClaims[i])) {
        continue;
      } else {
        isAuthorized = false;
        break;
      }
    }

    return isAuthorized;
  }
  /*
  static verifyJwt() {
    let token = sessionStorage.getItem("748784");
    let userInfo = null;

    if (!token) {
      window.location.replace("/login");
    } else {
      try {
        userInfo = JSON.parse(atob(token.split(".")[1]));
      } catch (ex) {
        sessionStorage.removeItem("748784");
        window.location.replace("/login");
      }
    }

    return axios
      .post(
        "/api/account/verifyjwt",
        {
          DFP: userInfo["100102112"],
          userId: this.getUserId(),
        },
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("748784"),
          },
        }
      )
      .then((jwtVerificationResponse) => {
        if (jwtVerificationResponse.data.respCode === 0) {
          sessionStorage.setItem(
            "748784",
            jwtVerificationResponse.data.respData.jWT
          );

          let refereshedToken = JSON.parse(
            atob(sessionStorage.getItem("748784").split(".")[1])
          );

          if (refereshedToken["100102112"] === "true") {
            if (window.location.pathname !== "/dashboard/changepassword") {
              toastError(
                "يرجى تغيير كلمة السر الافتراضية للنظام بكلمة سر جديدة خاصة بك"
              );
            }
          }
        } else {
          sessionStorage.removeItem("748784");
          window.location.replace("/login");
        }
      })
      .catch((apiResponse) => {
        sessionStorage.removeItem("748784");
        window.location.replace("/login");
      });
  }*/
}

export default sysJwt;
