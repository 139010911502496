/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import InfoPopUp from "../../commonComponents/infoPopUp";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";

class Appointment extends Component {
  state = {
    data: {
      appoId: "",
      patientName: "",
      drName: "",
      statusId: "",
    },

    dates: "",
    statusId: "",
    drId: "",

    contentHeader: "الحجوزات",

    dataUrl: "/api/data/getappos",
    singleItemUrl: "/api/data/getappo",
    editUrl: "/api/data/editappo",

    inEditMode: false,
    inModalMode: false,
    modalHeaderEdit: "تعديل الحجز",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    allStatus: [],
    allDrs: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "اسم المريض",
        field: "PATIENT_NAME",
      },
      {
        id: 3,
        label: "الطبيب",
        field: "DR_NAME",
      },
      {
        id: 4,
        label: "الوقت والتاريخ",
        field: "DATE",
        cellRenderer: ({ data }) => {
          return data.DATE + "-" + data.TIME;
        },
      },
      {
        id: 5,
        label: "الحالة",
        field: "STATUS",
      },
      {
        id: 6,
        label: "الاوامر",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="معلومات اكثر">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleMoreInfo(data);
                  }}
                >
                  <i className="fa fa-info" />
                </button>
              </div>
              <div className="inner" title="معلومات الدفعة">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleOrderInfo(data.ORDER_ID);
                  }}
                >
                  <i className="fa fa-money" />
                </button>
              </div>
              {sysJwt.isAuthorized(["CB09"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  handleMoreInfo(row) {
    const jsonData = {
      "تاريخ الادخال": row.INSERT_DATE,
      "تاريخ الحجز": row.DATE,
      "وقت الحجز": row.TIME,
      "اسم الطبيب": row.DR_NAME,
      "هاتف الطبيب": row.DR_PHONE,
      //"عنوان الطبيب": row.DR_ADDRESS,
      "مبلغ المشفية": row.COST,
      "اسم المريض": row.PATIENT_NAME,
      "هاتف المريض": row.PATIENT_PHONE,
      "معرف المريض": row.PATIENT_ID,
      " الملاحظات": row.NOTES,
      "الادوية الماخوذة": row.MEDICINE_TAKEN,
      " الاعراض": row.SYMPTOMS,
      " الحالة": row.STATUS,
      "طريقة الدفع": row.PAYMENT_METHOD,
      "حالة الدفع": row.PAYMENT_STATUS,
      "تاريخ الالغاء": row.CANCEL_DATE,
      "تاريخ الموافقة": row.APPROVE_DATE,
      "تاريخ الرفض": row.REJECT_DATE,
      "تاريخ الاكمال": row.COMPLETE_DATE,
      "تم التسوية": row.IS_SETTLED === "Y" ? "نعم" : "كلا",
      "تاريخ التسوية": row.SETTLE_DATE,
      "التقييم ": row.RATING,
      "ملاحظات التقييم": row.RATING_COMMENT,
      "تاريح التقييم": row.RATING_DATE,
      "رقم الدفعة": row.ORDER_ID,
    };

    confirmAlert({
      title: row.ID,
      childrenElement: () => (
        <InfoPopUp
          jsonData={jsonData}
          titlesColumnSize={4}
          valuesColumnSize={8}
          popUpWidth="600px"
        />
      ),
      buttons: [
        {
          label: "اغلاق",
          onClick: () => {
            null;
          },
        },
      ],
    });
  }

  handleOrderInfo(orderId) {
    post(
      "/api/data/getorder",
      { orderId: orderId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      console.log(apiResponse.data.respData);
      const jsonData = {
        "تاريخ الادخال": apiResponse.data.respData.data.INSERT_DATE,
        "تاريخ التعديل": apiResponse.data.respData.data.LAST_UPDATE,
        " اللغة": apiResponse.data.respData.data.LANG,
        "مكتمل ":
          apiResponse.data.respData.data.IS_COMPLETED === "Y" ? "نعم" : "كلا",
        "الدفعة مستقطعة":
          apiResponse.data.respData.data.IS_APPROVED === "Y" ? "نعم" : "كلا",
        "تاريخ الاكمال": apiResponse.data.respData.data.COMPLETE_DATE,
        "CHECKOUT_ID ": apiResponse.data.respData.data.CHECKOUT_ID,
        "CHECKOUT_RESP ": apiResponse.data.respData.data.CHECKOUT_RESP,
        "GET_STATUS_RESP ": apiResponse.data.respData.data.GET_STATUS_RESP,
      };

      confirmAlert({
        title: orderId,
        childrenElement: () => (
          <InfoPopUp
            jsonData={jsonData}
            titlesColumnSize={4}
            valuesColumnSize={8}
            popUpWidth="800px"
          />
        ),
        buttons: [
          {
            label: "اغلاق",
            onClick: () => {
              null;
            },
          },
        ],
      });
    });
  }

  getAllStatus() {
    post("/api/data/getallappostatus", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          allStatus: apiResponse.data.respData.data,
        });
      }
    );
  }

  getAllDrs() {
    post("/api/data/getalldrs", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          allDrs: apiResponse.data.respData.data,
        });
      }
    );
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["appoId"] = apiResponse.data.respData.data[0].ID;
      data["patientName"] = apiResponse.data.respData.data[0].PATIENT_NAME;
      data["drName"] = apiResponse.data.respData.data[0].DR_NAME;
      data["statusId"] = apiResponse.data.respData.data[0].STATUS_ID;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });

      console.log(this.state.data);
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    submitData["id"] = this.state.editEntityId;

    post(this.state.editUrl, submitData, this.props.updateIsLoading).then(
      (apiResponse) => {
        console.log(apiResponse);
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
        }
      }
    );
  }

  componentDidMount() {
    this.getAllStatus();
    this.getAllDrs();
  }

  getData() {
    post(
      this.state.dataUrl,
      {
        dateFrom: this.state.dates[0],
        dateTo: this.state.dates[1],
        statusId: this.state.statusId,
        drId: this.state.drId,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            تاريخ الادخال:
            <Calendar
              selectionMode="range"
              showIcon="true"
              value={this.state.dates}
              className="flex-item-left mr-2"
              onChange={(e) => this.setState({ dates: e.value })}
              style={{ width: "250px" }}
            ></Calendar>
            الطبيب:
            <Dropdown
              value={this.state.drId}
              showClear={true}
              options={this.state.allDrs}
              filter={true}
              onChange={(e) => this.setState({ drId: e.target.value })}
            />
            الحالة:
            <Dropdown
              value={this.state.statusId}
              showClear={true}
              options={this.state.allStatus}
              filter={true}
              onChange={(e) => this.setState({ statusId: e.target.value })}
            />
            <Button
              label="بحث"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                this.getData();
              }}
            />
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">{this.state.modalHeaderEdit}</h5>
          }
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelEdit}
                icon={this.state.modalSubmitButtonIconEdit}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "appoId",
                label: "رقم الحجز",
                disabled: true,
              },
              {
                type: "text",
                id: "drName",
                label: "اسم الطبيب",
                disabled: true,
              },
              {
                type: "text",
                id: "patientName",
                label: "اسم المريض",
                disabled: true,
              },
              {
                type: "dropdown",
                id: "statusId",
                label: "حالة الحجز",
                options: this.state.allStatus,
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default Appointment;
