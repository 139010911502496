/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post } from "../../../utils/sysAxios";
import GridTable from "@nadavshaar/react-grid-table";
import queryString from "query-string";
import { Button } from "primereact/button";

class UnsettledCash extends Component {
  state = {
    data: {},

    queryString: queryString.parse(window.location.search),

    contentHeader:
      "حركات النقد بانتظار التسوية - " +
      queryString.parse(window.location.search).drName,

    dataUrl: "/api/data/getunsettledcash",

    tableData: [],

    tableColumns: [
      {
        id: 1,
        field: "ID",
        label: "ID",
      },
      {
        id: 2,
        field: "PATIENT_NAME",
        label: "اسم المريض",
      },
      {
        id: 3,
        label: "الوقت والتاريخ",
        field: "DATE",
        cellRenderer: ({ data }) => {
          return data.DATE + "-" + data.TIME;
        },
      },
      {
        id: 4,
        field: "COST",
        label: "سعر الكشفية",
      },
      {
        id: 5,
        field: "SYS_FEE",
        label: "عمولة النظام",
      },
      {
        id: 6,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      { drId: this.state.queryString.drId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <Button
              label="رجوع"
              icon="fa fa-arrow-left"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                window.location.replace("/dashboard/unsettledappos");
              }}
            />
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default UnsettledCash;
