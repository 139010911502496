/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post } from "../../../utils/sysAxios";
import GridTable from "@nadavshaar/react-grid-table";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { getDmsDate2 } from "../../../utils/stringHelper";

class SettledAppointments extends Component {
  state = {
    data: {
      questionEn: "",
      questionAr: "",
      questionKu: "",
      answerEn: "",
      answerAr: "",
      answerKu: "",
    },

    dates: "",

    contentHeader: "الحجوزات تم التسوية",

    dataUrl: "/api/data/getsettledappos",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "معرف الطبيب",
        field: "DR_ID",
      },
      {
        id: 2,
        label: "اسم الطبيب",
        field: "DR_NAME",
      },
      {
        id: 3,
        label: "مجموع المبالغ النقد(مستلم)",
        field: "CASH_SUM",
        width: "250px",
      },
      {
        id: 4,
        label: "مجموع المبالغ الكترونياً(مدفوع)",
        field: "VISAMC_SUM",
        width: "250px",
      },
      {
        id: 5,
        label: "الاوامر",
        field: "SYS_ACTINOS",
        width: "120px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="تفاصيل الحركات النقد">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    window.location.replace(
                      "/dashboard/settledcash?drId=" +
                        data.DR_ID +
                        "&drName=" +
                        data.DR_NAME +
                        "&dateFrom=" +
                        getDmsDate2(this.state.dates[0]) +
                        "&dateTo=" +
                        getDmsDate2(this.state.dates[1])
                    );
                  }}
                >
                  <i className="fa fa-money" />
                </button>
              </div>
              <div className="inner" title="تفاصيل الحركات الالكترونية">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    window.location.replace(
                      "/dashboard/settledvisamc?drId=" +
                        data.DR_ID +
                        "&drName=" +
                        data.DR_NAME +
                        "&dateFrom=" +
                        getDmsDate2(this.state.dates[0]) +
                        "&dateTo=" +
                        getDmsDate2(this.state.dates[1])
                    );
                  }}
                >
                  <i className="fa fa-credit-card" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    //this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      {
        dateFrom: this.state.dates[0],
        dateTo: this.state.dates[1],
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      console.log(apiResponse.data.respData);
      this.setState({ tableData: apiResponse.data.respData });
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            تاريخ الحجز:
            <Calendar
              selectionMode="range"
              showIcon="true"
              value={this.state.dates}
              className="flex-item-left mr-2"
              onChange={(e) => this.setState({ dates: e.value })}
              style={{ width: "250px" }}
            ></Calendar>
            <Button
              label="بحث"
              className="p-button-rounded p-button-secondary flex-item-left mr-2"
              disabled={this.props.isLoading}
              onClick={() => {
                this.getData();
              }}
            />
          </div>
        </div>

        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default SettledAppointments;
