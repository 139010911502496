/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import { post } from "../../../utils/sysAxios";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";

class UnsettledAppointments extends Component {
  state = {
    data: {
      questionEn: "",
      questionAr: "",
      questionKu: "",
      answerEn: "",
      answerAr: "",
      answerKu: "",
    },

    contentHeader: "الحجوزات بانتظار التسوية",

    dataUrl: "/api/data/getunsettledappos",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "معرف الطبيب",
        field: "DR_ID",
      },
      {
        id: 2,
        label: "اسم الطبيب",
        field: "DR_NAME",
      },
      {
        id: 3,
        label: "مجموع المبالغ النقد(مطلوب)",
        field: "CASH_SUM",
        width: "250px",
      },
      {
        id: 4,
        label: "مجموع المبالغ الكترونياً(يطلب)",
        field: "VISAMC_SUM",
        width: "250px",
      },
      {
        id: 5,
        label: "الاوامر",
        field: "SYS_ACTINOS",
        width: "120px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="تفاصيل الحركات النقد">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    window.location.replace(
                      "/dashboard/unsettledcash?drId=" +
                        data.DR_ID +
                        "&drName=" +
                        data.DR_NAME
                    );
                  }}
                >
                  <i className="fa fa-money" />
                </button>
              </div>
              <div className="inner" title="تفاصيل الحركات الالكترونية">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    window.location.replace(
                      "/dashboard/unsettledvisamc?drId=" +
                        data.DR_ID +
                        "&drName=" +
                        data.DR_NAME
                    );
                  }}
                >
                  <i className="fa fa-credit-card" />
                </button>
              </div>
              {sysJwt.isAuthorized(["CB0C"]) && (
                <div className="inner" title="تسوية الحركات النقدية">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.settleCash(data)}
                  >
                    <i className="fa fa-sign-in" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB0D"]) && (
                <div className="inner" title="تسوية الحركات الالكترونية">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.settleVisaMc(data)}
                  >
                    <i className="fa fa-paper-plane" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB0E"]) && (
                <div className="inner" title="تسوية جميع الحركات">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.settleAll(data)}
                  >
                    <i className="fa fa-bars" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        console.log(apiResponse.data.respData);
        this.setState({ tableData: apiResponse.data.respData });
      }
    );
  }

  settleCash(row) {
    confirmAlert({
      title: "تسوية الحركات النقد",
      message: "هل انت متأكد من تسوية الحركات النقدية لهذا الطبيب؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              "/api/data/settlecash",
              { drId: row.DR_ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "الغاء",
          onClick: () => null,
        },
      ],
    });
  }

  settleVisaMc(row) {
    confirmAlert({
      title: "تسوية الحركات الالكترونية",
      message: "هل انت متأكد من تسوية الحركات الالكترونية لهذا الطبيب؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              "/api/data/settlevisamc",
              { drId: row.DR_ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "الغاء",
          onClick: () => null,
        },
      ],
    });
  }

  settleAll(row) {
    confirmAlert({
      title: "تسوية جميع الحركات ",
      message: "هل انت متأكد من تسوية جميع الحركات لهذا الطبيب؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              "/api/data/settleall",
              { drId: row.DR_ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "الغاء",
          onClick: () => null,
        },
      ],
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default UnsettledAppointments;
