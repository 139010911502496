/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import { post } from "../../../utils/sysAxios";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import InfoPopUp from "../../commonComponents/infoPopUp";

class AppUsers extends Component {
  state = {
    data: {},

    contentHeader: "مستخدمين التطبيق",

    dataUrl: "/api/data/getappusers",

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "رقم الهاتف",
        field: "PHONE_NUM",
      },
      {
        id: 2,
        label: "اسم المستخدم",
        field: "FULL_NAME",
      },
      {
        id: 3,
        label: "الجنس",
        field: "GENDER",
        width: "100px",
        cellRenderer: ({ data }) => {
          return data.GENDER === "M" ? "ذكر" : "أنثى";
        },
      },
      {
        id: 4,
        label: "نوع المستخدم",
        field: "USER_TYPE",
      },
      {
        id: 5,
        label: "محظور",
        field: "IS_BLOCKED",
        width: "100px",
        cellRenderer: ({ data }) => {
          return data.IS_BLOCKED === "Y" ? (
            <div style={{ color: "red" }}>نعم</div>
          ) : (
            <div>كلا</div>
          );
        },
      },
      {
        id: 6,
        label: "فعال",
        field: "IS_ACTIVE",
        width: "100px",
        cellRenderer: ({ data }) => {
          return data.IS_ACTIVE === "Y" ? (
            <div style={{ color: "green" }}>نعم</div>
          ) : (
            <div style={{ color: "red" }}>كلا</div>
          );
        },
      },
      {
        id: 7,
        label: "محذوف",
        field: "IS_DELETED",
        width: "100px",
        cellRenderer: ({ data }) => {
          return data.IS_DELETED === "Y" ? (
            <div style={{ color: "red" }}>نعم</div>
          ) : (
            <div>كلا</div>
          );
        },
      },
      {
        id: 8,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "90px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="معلومات اكثر">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleMoreInfo(data);
                  }}
                >
                  <i className="fa fa-info" />
                </button>
              </div>
              {sysJwt.isAuthorized(["CB03"]) && (
                <div className="inner" title="ايقاف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDeactivate(data)}
                  >
                    <i className="fa fa-ban" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB02"]) && (
                <div className="inner" title="تغعيل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleActivate(data)}
                  >
                    <i className="fa fa-check" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  handleActivate(row) {
    post(
      "/api/data/activateuser",
      { UserId: row.ID },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  handleDeactivate(row) {
    post(
      "/api/data/deactivateuser",
      { UserId: row.ID },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  handleMoreInfo(row) {
    const jsonData = {
      "العنوان ": row.ADDRESS,
      "تاريخ الميلاد": row.BIRTHDATE,
      "صنف الدم": row.BLOOD_TYPE,
      "البريد الالكتروني": row.EMAIL,
      "عدد محاولات تسجيل الدخول الخطا": row.FAILED_LOGIN_COUNTER,
      "اخر تسجيل دخول": row.LAST_LOGIN_DATE,
      "تاريخ الحضر": row.BLOCK_DATE,
      "تاريخ الايقاف": row.DEACTIVATE_DATE,
      "معرف الطبيب": row.DR_ID,
      "تاريخ الحذف": row.IS_DELETED,
    };

    confirmAlert({
      title: row.FULL_NAME,
      childrenElement: () => (
        <InfoPopUp
          jsonData={jsonData}
          titlesColumnSize={4}
          valuesColumnSize={8}
          popUpWidth="600px"
        />
      ),
      buttons: [
        {
          label: "اغلاق",
          onClick: () => {
            null;
          },
        },
      ],
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default AppUsers;
