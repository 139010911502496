/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import queryString from "query-string";

class DrServices extends Component {
  state = {
    data: {
      titleEn: "",
      titleAr: "",
      titleKu: "",
      detailsEn: "",
      detailsAr: "",
      detailsKu: "",
    },

    queryString: queryString.parse(window.location.search),

    contentHeader:
      "خدمات الطبيب - " + queryString.parse(window.location.search).drName,
    addButtonLabel: "اضافة خدمة",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getdrservices",
    singleItemUrl: "/api/data/getdrservice",
    addUrl: "/api/data/adddrservice",
    editUrl: "/api/data/editdrservice",
    deleteUrl: "/api/data/deletedrservice",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة خدمة",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل خدمة",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,
    tableData: [],

    tableColumns: [
      {
        id: 1,
        field: "TITLE_EN",
        label: "عنوان بالانكليزية",
      },
      {
        id: 2,
        field: "TITLE_AR",
        label: "عنوان بالعربية",
      },
      {
        id: 3,
        field: "TITLE_KU",
        label: "عنوان بالكردية",
      },
      {
        id: 4,
        field: "DETAILS_EN",
        label: "التفاصيل بالانكليزية",
      },
      {
        id: 5,
        field: "DETAILS_AR",
        label: "التفاصيل بالعربية",
      },
      {
        id: 6,
        field: "DETAILS_KU",
        label: "التفاصيل بالكردية",
      },
      {
        id: 7,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 8,
        label: "اخر تحديث",
        field: "LAST_UPDATE",
      },
      {
        id: 9,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CAF9"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAFA"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      { drId: this.state.queryString.drId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["titleEn"] = apiResponse.data.respData.data[0].TITLE_EN;
      data["titleAr"] = apiResponse.data.respData.data[0].TITLE_AR;
      data["titleKu"] = apiResponse.data.respData.data[0].TITLE_KU;
      data["detailsEn"] = apiResponse.data.respData.data[0].DETAILS_EN;
      data["detailsAr"] = apiResponse.data.respData.data[0].DETAILS_AR;
      data["detailsKu"] = apiResponse.data.respData.data[0].DETAILS_KU;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف الخدمة",
      message: "هل انت متأكد من حذف الخدمة",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "الغاء",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    if (this.state.inEditMode) {
      post(
        this.state.editUrl,
        {
          id: this.state.editEntityId,
          titleEn: this.state.data.titleEn,
          titleAr: this.state.data.titleAr,
          titleKu: this.state.data.titleKu,
          detailsEn: this.state.data.detailsEn,
          detailsAr: this.state.data.detailsAr,
          detailsKu: this.state.data.detailsKu,
        },
        this.props.updateIsLoading
      ).then((apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
        }
      });
    } else {
      post(
        this.state.addUrl,
        {
          drId: this.state.queryString.drId,
          titleEn: this.state.data.titleEn,
          titleAr: this.state.data.titleAr,
          titleKu: this.state.data.titleKu,
          detailsEn: this.state.data.detailsEn,
          detailsAr: this.state.data.detailsAr,
          detailsKu: this.state.data.detailsKu,
        },
        this.props.updateIsLoading
      ).then((apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
        }
      });
    }
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CAF8"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "titleEn",
                label: "عنوان بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "titleAr",
                label: "عنوان بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "titleKu",
                label: "عنوان بالكردية",
                disabled: false,
              },
              {
                type: "text",
                id: "detailsEn",
                label: "التفاصيل بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "detailsAr",
                label: "التفاصيل بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "detailsKu",
                label: "التفاصيل بالكردية",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default DrServices;
