/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";

class Slider extends Component {
  state = {
    data: {
      titelEn: "",
      titelAr: "",
      titelKu: "",
      order: "",
      file: null,
    },

    contentHeader: "متصفح الصور الصفحة الرئيسية",
    addButtonLabel: "اضافة صورة",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getslides",
    singleItemUrl: "/api/data/getslide",
    addUrl: "/api/data/addslide",
    editUrl: "/api/data/editdrspec",
    deleteUrl: "/api/data/deleteslide",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة صورة",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل صورة",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        field: "TITLE_EN",
        label: "العنوان بالانكليزية",
      },
      {
        id: 2,
        field: "TITLE_AR",
        label: "العنوان بالعربية",
      },
      {
        id: 3,
        field: "TITLE_KU",
        label: "العنوان بالكردية",
      },
      {
        id: 4,
        label: "التسلسل",
        field: "ORDERX",
        width: "100px",
      },
      {
        id: 5,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 6,
        label: "الصورة",
        field: "IMG",
        width: "90px",
        resizable: false,
        cellRenderer: ({ data }) => {
          return (
            <a href={data.IMG} target="blank">
              عرض الصورة
            </a>
          );
        },
      },
      {
        id: 7,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB00"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      key === "file"
        ? (data[key] = null)
        : Array.isArray(data[key])
        ? (data[key] = [])
        : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف الصورة",
      message: "هل انت متأكد من حذف هذة الصورة؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "الغاء",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    const data = new FormData();
    if (this.state.data.file !== null) {
      data.append("File", this.state.data.file, this.state.data.file.name);
    }

    data.append(
      "requestData",
      JSON.stringify({
        titelEn: this.state.data.titelEn,
        titelAr: this.state.data.titelAr,
        titelKu: this.state.data.titelKu,
        order: this.state.data.order,
      })
    );

    post(this.state.addUrl, data, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
        }
      }
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CAFF"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{this.state.modalHeaderAdd}</h5>}
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelAdd}
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "titelEn",
                label: "العنوان بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "titelAr",
                label: "العنوان بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "titelKu",
                label: "العنوان بالكردية",
                disabled: false,
              },
              {
                type: "text",
                id: "order",
                label: "التسلسل",
                disabled: false,
              },
              {
                type: "fileupload",
                id: "file",
                label: "الصورة",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default Slider;
