import React from "react";

const InfoPopUp = ({
  jsonData,
  titlesColumnSize,
  valuesColumnSize,
  popUpWidth
}) => {
  var keysArray = [];
  var valuesArray = [];

  Object.keys(jsonData).forEach(function(key) {
    keysArray.push(key);
    valuesArray.push(jsonData[key]);
  });

  var titlesClassString =
    "selection font-weight-bold col-sm-" + titlesColumnSize;
  var valuesClassString = "selection col-sm-" + valuesColumnSize;

  return (
    <div style={{ width: popUpWidth }}>
      <hr style={{ borderWidth: "3px" }} />
      {keysArray.map((key, index) => {
        return (
          <React.Fragment key={index}>
            <div className="row">
              <div
                className={titlesClassString}
                style={{ textAlign: "right", fontSize: "15px" }}
              >
                <label>{key}:</label>
              </div>
              <div
                className={valuesClassString}
                style={{
                  textAlign: "right",
                  fontSize: "13px"
                }}
              >
                <label>{valuesArray[index]}</label>
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default InfoPopUp;
