import React, { Component } from "react";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";
import sysJwt from "../../../utils/sysJwt";

class UserManagement extends Component {
  state = {
    data: {
      title: "",
      body: "",
      topic: "",
      userId: "",
    },

    contentHeader: "الاشعارات العامة",
    addButtonLabel: "ارسال اشعار جديد",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getpublicnotf",
    addUrl: "/api/data/sendpublicnotf",

    inModalMode: false,
    modalHeaderAdd: "ارسال اشعار",
    modalSubmitButtonLabelAdd: "ارسال",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalWidth: "50vw",

    topicDropdown: [],
    allUsers: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "العنوان",
        field: "TITLE",
      },
      {
        id: 2,
        label: "المحتوى",
        field: "BODY",
      },
      {
        id: 3,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 4,
        label: "اخر تحديث",
        field: "LAST_UPDATE",
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getTopicDropdown();
    this.getAllAppUsersDropdown();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      data: data,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getTopicDropdown() {
    post("/api/data/getallfcmtopic", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          topicDropdown: apiResponse.data.respData.data,
        });
      }
    );
  }

  getAllAppUsersDropdown() {
    post(
      "/api/data/GetAllAppUsersDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allUsers: apiResponse.data.respData.data,
      });
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    post(this.state.addUrl, submitData, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
        }
      }
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB11"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{this.state.modalHeaderAdd}</h5>}
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelAdd}
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={false}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "topic",
                label: "FCM Topic",
                options: this.state.topicDropdown,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "userId",
                label: "المستخدم",
                options: this.state.allUsers,
                disabled: this.state.data.topic === 1 ? true : false,
              },
              {
                type: "text",
                id: "title",
                label: "العنوان",
                disabled: false,
              },
              {
                type: "text",
                id: "body",
                label: "المحتوى",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default UserManagement;
