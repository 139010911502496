/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import InfoPopUp from "../../commonComponents/infoPopUp";

class Faq extends Component {
  state = {
    data: {
      questionEn: "",
      questionAr: "",
      questionKu: "",
      answerEn: "",
      answerAr: "",
      answerKu: "",
    },

    contentHeader: "الأسئلة الشائعة",
    addButtonLabel: "اضافة سؤال",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getfaqs",
    singleItemUrl: "/api/data/getfaq",
    addUrl: "/api/data/addfaq",
    editUrl: "/api/data/editfaq",
    deleteUrl: "/api/data/deletefaq",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة سؤال",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل سؤال",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "السوال بالعربية",
        field: "QUESTION_AR",
      },
      {
        id: 2,
        label: "الاجابة بالعربية",
        field: "ANSWER_AR",
      },
      {
        id: 3,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 4,
        label: "اخر تحديث",
        field: "LAST_UPDATE",
      },
      {
        id: 5,
        label: "الاوامر",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="معلومات اكثر">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleMoreInfo(data);
                  }}
                >
                  <i className="fa fa-info" />
                </button>
              </div>
              {sysJwt.isAuthorized(["CB06"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CB07"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  handleMoreInfo(row) {
    const jsonData = {
      "السوال بالانكليزية": row.QUESTION_EN,
      "الاجابة بالانكليزية": row.ANSWER_EN,
      "السوال بالكردية": row.QUESTION_KU,
      "الاجابة بالكردية": row.ANSWER_KU,
    };

    confirmAlert({
      title: row.QUESTION_AR,
      childrenElement: () => (
        <InfoPopUp
          jsonData={jsonData}
          titlesColumnSize={4}
          valuesColumnSize={8}
          popUpWidth="600px"
        />
      ),
      buttons: [
        {
          label: "اغلاق",
          onClick: () => {
            null;
          },
        },
      ],
    });
  }

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
      allClaims: [],
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["questionEn"] = apiResponse.data.respData.data[0].QUESTION_EN;
      data["questionAr"] = apiResponse.data.respData.data[0].QUESTION_AR;
      data["questionKu"] = apiResponse.data.respData.data[0].QUESTION_KU;
      data["answerEn"] = apiResponse.data.respData.data[0].ANSWER_EN;
      data["answerAr"] = apiResponse.data.respData.data[0].ANSWER_AR;
      data["answerKu"] = apiResponse.data.respData.data[0].ANSWER_KU;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف السؤال",
      message: "هل انت متأكد من حذف هذا السؤال؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "الغاء",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["id"] = this.state.editEntityId;
    }
    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      console.log(apiResponse);
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
      }
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB05"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "questionEn",
                label: "السؤال بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "questionAr",
                label: "السؤال بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "questionKu",
                label: "السؤال بالكردية",
                disabled: false,
              },
              {
                type: "text",
                id: "answerEn",
                label: "الاجابة بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "answerAr",
                label: "الاجابة بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "answerKu",
                label: "الاجابة بالكردية",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default Faq;
