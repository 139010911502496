export function apiDataToDropdownList(apiData, label, value) {
  var options = [];

  for (var i = 0; i < apiData.length; i++) {
    var option = {
      label: apiData[i][label],
      value: apiData[i][value]
    };
    options.push(option);
  }

  return options;
}
