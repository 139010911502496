import React, { Component } from "react";
import homelogo from "../../images/homeimg.jpg";

class Home extends Component {
  state = {};

  componentDidMount() {
    console.log("2");
  }

  render() {
    return (
      <React.Fragment>
        <div className="home-logo text-center">
          <img src={homelogo} width={1000} height={500} alt="HOME" />
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
