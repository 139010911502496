import React, { Component } from "react";
import LoginForm from "./components/mainComponents/loginForm";
import Dashboard from "./components/mainComponents/dashboard";
import { Route, Routes, Navigate } from "react-router-dom";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <Routes>
        <Route path="/login" element={<LoginForm />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }
}
