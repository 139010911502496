/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import queryString from "query-string";
import { getTime } from "../../../utils/stringHelper";

class DrTime extends Component {
  state = {
    data: {
      day: "",
      timeFrom: "",
      timeTo: "",
      duration: "",
    },

    queryString: queryString.parse(window.location.search),

    contentHeader: "اوقات الطبيب",
    addButtonLabel: "اضافة يوم",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getdrtimes",
    singleItemUrl: "/api/data/getdrtime",
    addUrl: "/api/data/adddrtime",
    editUrl: "/api/data/editdrtime",
    deleteUrl: "/api/data/deletedrtime",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة يوم",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل يوم",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,
    allDays: [
      { label: "Saturday", value: "saturday" },
      { label: "Sunday", value: "sunday" },
      { label: "Monday", value: "monday" },
      { label: "Tuesday", value: "tuesday" },
      { label: "Wednesday", value: "wednesday" },
      { label: "Thursday", value: "thursday" },
      { label: "Friday", value: "friday" },
    ],
    allDuration: [
      { label: "5 Minutes", value: "5" },
      { label: "10 Minutes", value: "10" },
      { label: "15 Minutes", value: "15" },
      { label: "20 Minutes", value: "20" },
      { label: "25 Minutes", value: "25" },
      { label: "30 Minutes", value: "30" },
    ],
    tableData: [],
    tableColumns: [
      {
        id: 1,
        field: "NAME_AR",
        label: "اسم الطبيب",
      },
      {
        id: 2,
        field: "DAY",
        label: "اليوم",
      },
      {
        id: 3,
        field: "TIME_FROM",
        label: "الوقت من",
      },
      {
        id: 4,
        field: "TIME_TO",
        label: "الوقت الى",
      },
      {
        id: 5,
        field: "DURATION",
        label: "فترة الزيارة",
      },
      {
        id: 6,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 7,
        label: "اخر تحديث",
        field: "LAST_UPDATE",
      },
      {
        id: 8,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CAFC"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAFD"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      { drId: this.state.queryString.drId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["day"] = apiResponse.data.respData.data[0].DAY;
      data["timeFrom"] = new Date(
        "2022-01-01 " + apiResponse.data.respData.data[0].TIME_FROM
      );
      data["timeTo"] = new Date(
        "2022-01-01 " + apiResponse.data.respData.data[0].TIME_TO
      );
      data["duration"] = apiResponse.data.respData.data[0].DURATION;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف اليوم",
      message: "هل انت متأكد من حذف اليوم",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "الغاء",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    if (this.state.inEditMode) {
      post(
        this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
        {
          id: this.state.editEntityId,
          timeFrom: getTime(this.state.data.timeFrom),
          timeTo: getTime(this.state.data.timeTo),
          duration: this.state.data.duration,
        },
        this.props.updateIsLoading
      ).then((apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
        }
      });
    } else {
      post(
        this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
        {
          drId: this.state.queryString.drId,
          day: this.state.data.day,
          timeFrom: getTime(this.state.data.timeFrom),
          timeTo: getTime(this.state.data.timeTo),
          duration: this.state.data.duration,
        },
        this.props.updateIsLoading
      ).then((apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
        }
      });
    }
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CAFB"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "day",
                label: "اليوم",
                options: this.state.allDays,
                disabled: this.state.inEditMode ? true : false,
              },
              {
                type: "time",
                id: "timeFrom",
                label: "الوقت من",
                hourFormat: "12",
                disabled: false,
              },
              {
                type: "time",
                id: "timeTo",
                label: "الوقت الى",
                hourFormat: "12",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "duration",
                label: "الفترة",
                options: this.state.allDuration,
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default DrTime;
