import React, { Component } from "react";
import Axios from "axios";
import { ThreeCircles } from "react-loader-spinner";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { toastError } from "../../utils/sysToast.js";
import { ToastContainer, Flip } from "react-toastify";
import logo from "../../images/loginIcon.png";

class LoginForm extends Component {
  state = {
    Username: "",
    Password: "",
    isLoading: false,
    errDesc: "",
  };

  componentDidMount() {
    document
      .getElementById("username")
      .addEventListener("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          document.getElementById("btnLogin").click();
        }
      });
    document
      .getElementById("password")
      .addEventListener("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          document.getElementById("btnLogin").click();
        }
      });
  }

  handleLogin() {
    this.setState({ isLoading: true });

    Axios.post("/api/account/dashboardlogin", {
      username: this.state.Username,
      password: this.state.Password,
    })
      .then((apiResponse) => {
        this.setState({ isLoading: false });

        if (apiResponse.data.respCode === 0) {
          sessionStorage.setItem("748784", apiResponse.data.respData.jWT);
          window.location.replace("/dashboard/home");
        } else {
          this.setState({ errDesc: apiResponse.data.respDesc });
          //toastError(apiResponse.data.respDesc);
        }
      })
      .catch((apiResponse) => {
        this.setState({ isLoading: false });
        console.log(apiResponse);
        toastError(apiResponse.response.statusText);
      });
  }

  render() {
    return (
      <div className="main">
        <div className="container">
          <center>
            <div className="">
              <div id="login">
                <img src={logo} height="270" width="400" alt="System Logo" />
                <br />
                <label className="eer-label">{this.state.errDesc}</label>

                <span className="p-float-label">
                  <InputText
                    id="username"
                    value={this.state.Username}
                    className="login-input"
                    placeholder="اسم المستخدم"
                    onChange={(e) =>
                      this.setState({ Username: e.target.value })
                    }
                  />
                  <label htmlFor="username" className="login-label">
                    &nbsp;اسم المستخدم
                  </label>
                </span>
                <span className="p-float-label">
                  <InputText
                    id="password"
                    value={this.state.Password}
                    className="login-input"
                    placeholder="كلمة السر"
                    type="password"
                    onChange={(e) =>
                      this.setState({ Password: e.target.value })
                    }
                  />
                  <label htmlFor="password" className="login-label">
                    &nbsp;كلمة السر
                  </label>
                </span>
                <div className="text-right">
                  <Button
                    id="btnLogin"
                    label={
                      <label style={{ paddingRight: "45%" }}>
                        {this.state.isLoading ? (
                          <ThreeCircles color="white" width="30" height="30" />
                        ) : (
                          "دخول"
                        )}
                      </label>
                    }
                    className="p-button-raised p-button-rounded login-button"
                    style={{ "text-align": "center" }}
                    onClick={() => {
                      this.handleLogin();
                    }}
                  />
                </div>
                <div className="clearfix" />
                <div className="clearfix" />
              </div>
            </div>
          </center>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          closeButton={false}
          rtl={false}
          transition={Flip}
          newestOnTop
          closeOnClick
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default LoginForm;
