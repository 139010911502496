/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import InfoPopUp from "../../commonComponents/infoPopUp";

class Doctors extends Component {
  state = {
    data: {
      nameEn: "",
      nameAr: "",
      nameKu: "",
      specId: "",
      phoneNum: "",
      aboutEn: "",
      aboutAr: "",
      aboutKu: "",
      healthCareEn: "",
      healthCareAr: "",
      healthCareKu: "",
      addressEn: "",
      addressAr: "",
      addressKu: "",
      location: "",
      latitude: "",
      longitude: "",
      appoCost: "",
      appSysFee: "",
      maxDailyAppo: "",
      isActive: "",
      password: "",
      birthdate: "",
      email: "",
      gender: "",
      appNumDaysAhead: "",
      file: null,
    },

    contentHeader: "الأطباء",
    addButtonLabel: "اضافة طبيب",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getdrs",
    singleItemUrl: "/api/data/getdr",
    addUrl: "/api/data/adddr",
    editUrl: "/api/data/editdr",
    deleteUrl: "/api/data/deletedr",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "اضافة طبيب",
    modalSubmitButtonLabelAdd: "اضافة",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "تعديل طبيب",
    modalSubmitButtonLabelEdit: "تعديل",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "75vw",

    editEntityId: 0,
    allSpecs: [],
    allStatus: [
      { label: "فعال", value: "Y" },
      { label: "غير فعال", value: "N" },
    ],
    allGender: [
      { label: "ذكر", value: "M" },
      { label: "أنثى", value: "F" },
    ],
    tableData: [],
    tableColumns: [
      {
        id: 1,
        field: "NAME_AR",
        label: "الاسم",
      },
      {
        id: 2,
        field: "SPEC_NAME_AR",
        label: "الاختصاص",
      },
      {
        id: 3,
        field: "IS_ACTIVE",
        label: "الحالة",
        cellRenderer: ({ data }) => {
          return data.IS_ACTIVE === "Y" ? "فعال" : "غير فعال";
        },
      },
      {
        id: 4,
        field: "PHONE_NUM",
        label: "رقم الهاتف",
      },
      {
        id: 5,
        label: "تاريخ الادخال",
        field: "INSERT_DATE",
      },
      {
        id: 6,
        label: "الصورة",
        field: "IMG",
        width: "100px",
        resizable: false,
        cellRenderer: ({ data }) => {
          return (
            <a href={data.IMG} target="blank">
              عرض الصورة
            </a>
          );
        },
      },
      {
        id: 7,
        label: "الاوامر",
        field: "SYS_ACTIONS",
        width: "120px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="معلومات اكثر">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleMoreInfo(data);
                  }}
                >
                  <i className="fa fa-info" />
                </button>
              </div>
              {sysJwt.isAuthorized(["CAF9"]) && (
                <div className="inner" title="تعديل">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAFA"]) && (
                <div className="inner" title="حذف">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
              <div className="inner" title="الخدمات">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => this.handleShowDrServices(data)}
                >
                  <i className="fa fa-th-large" />
                </button>
              </div>
              <div className="inner" title="اوقات التواجد">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => this.handleShowDrTime(data)}
                >
                  <i className="fa fa-clock-o" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };
  handleShowDrTime(row) {
    window.location.replace("/dashboard/drtime?drId=" + row.ID);
  }
  handleShowDrServices(row) {
    window.location.replace(
      "/dashboard/drservices?drId=" + row.ID + "&drName=" + row.NAME_AR
    );
  }
  handleMoreInfo(row) {
    const jsonData = {
      "الاسم بالانكليزية": row.NAME_EN,
      "الاسم بالكردية": row.NAME_KU,
      "تاريخ الميلاد": row.BIRTHDATE,
      "الجنس ": row.GENDER === "M" ? "ذكر" : "أنثى",
      "البريد الالكتروني": row.EMAIL,
      "سعر الكشفية": row.APPO_COST,
      "عمولة النظام": row.APPO_SYS_FEE,
      "عدد الحجوزات اليومي": row.MAX_DAILY_APPO,
      "عدد الأيام المقبلة للحجز": row.APPO_NUM_DAYS_AHEAD,
      "عن الطبيب بالانكليزية": row.ABOUT_EN,
      "عن الطبيب بالعربية": row.ABOUT_AR,
      "عن الطبيب بالكردية": row.ABOUT_KU,
      "الرعاية الصحية بالانكليزية": row.HEALTH_CARE_EN,
      "الرعاية الصحية بالعربية": row.HEALTH_CARE_AR,
      "الرعاية الصحية بالكردية": row.HEALTH_CARE_KU,
      "العنوان بالانكليزية": row.ADDRESS_EN,
      "العنوان بالعربية": row.ADDRESS_AR,
      "العنوان بالكردية": row.ADDRESS_KU,
      "الموقع ": row.LOCATION,
      "LATITUDE ": row.LATITUDE,
      "LONGITUDE ": row.LONGITUDE,
    };

    confirmAlert({
      title: row.NAME_AR,
      childrenElement: () => (
        <InfoPopUp
          jsonData={jsonData}
          titlesColumnSize={4}
          valuesColumnSize={8}
          popUpWidth="600px"
        />
      ),
      buttons: [
        {
          label: "اغلاق",
          onClick: () => {
            null;
          },
        },
      ],
    });
  }

  componentDidMount() {
    this.getData();
    this.getAllSpecs();
  }

  getAllSpecs() {
    post("/api/data/getalldrspecs", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          allSpecs: apiResponse.data.respData.data,
        });
      }
    );
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      key === "file"
        ? (data[key] = null)
        : Array.isArray(data[key])
        ? (data[key] = [])
        : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["nameEn"] = apiResponse.data.respData.data[0].NAME_EN;
      data["nameAr"] = apiResponse.data.respData.data[0].NAME_AR;
      data["nameKu"] = apiResponse.data.respData.data[0].NAME_KU;
      data["specId"] = apiResponse.data.respData.data[0].SPEC_ID;
      data["phoneNum"] = apiResponse.data.respData.data[0].PHONE_NUM;
      data["aboutEn"] = apiResponse.data.respData.data[0].ABOUT_EN;
      data["aboutAr"] = apiResponse.data.respData.data[0].ABOUT_AR;
      data["aboutKu"] = apiResponse.data.respData.data[0].ABOUT_KU;
      data["healthCareEn"] = apiResponse.data.respData.data[0].HEALTH_CARE_EN;
      data["healthCareAr"] = apiResponse.data.respData.data[0].HEALTH_CARE_AR;
      data["healthCareKu"] = apiResponse.data.respData.data[0].HEALTH_CARE_KU;
      data["addressEn"] = apiResponse.data.respData.data[0].ADDRESS_EN;
      data["addressAr"] = apiResponse.data.respData.data[0].ADDRESS_AR;
      data["addressKu"] = apiResponse.data.respData.data[0].ADDRESS_KU;
      data["location"] = apiResponse.data.respData.data[0].LOCATION;
      data["latitude"] = apiResponse.data.respData.data[0].LATITUDE;
      data["longitude"] = apiResponse.data.respData.data[0].LONGITUDE;
      data["appoCost"] = apiResponse.data.respData.data[0].APPO_COST;
      data["isActive"] = apiResponse.data.respData.data[0].IS_ACTIVE;
      data["appSysFee"] = apiResponse.data.respData.data[0].APPO_SYS_FEE;
      data["maxDailyAppo"] = apiResponse.data.respData.data[0].MAX_DAILY_APPO;
      data["gender"] = apiResponse.data.respData.data[0].GENDER;
      data["email"] = apiResponse.data.respData.data[0].EMAIL;
      data["birthdate"] = apiResponse.data.respData.data[0].BIRTHDATE;
      data["appNumDaysAhead"] =
        apiResponse.data.respData.data[0].APPO_NUM_DAYS_AHEAD;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "حذف الطبيب",
      message: "هل انت متأكد من حذف هذا الطبيب؟",
      buttons: [
        {
          label: "نعم",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { id: row.ID, RoleName: row.ROLE_NAME },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "الغاء",
          onClick: () => null,
        },
      ],
    });
  }

  doSubmit() {
    const data = new FormData();
    if (this.state.data.file !== null) {
      data.append("File", this.state.data.file, this.state.data.file.name);
    }

    if (this.state.inEditMode) {
      data.append(
        "requestData",
        JSON.stringify({
          id: this.state.editEntityId,
          nameEn: this.state.data.nameEn,
          nameAr: this.state.data.nameAr,
          nameKu: this.state.data.nameKu,
          specId: this.state.data.specId,
          aboutEn: this.state.data.aboutEn,
          aboutAr: this.state.data.aboutAr,
          aboutKu: this.state.data.aboutKu,
          healthCareEn: this.state.data.healthCareEn,
          healthCareAr: this.state.data.healthCareAr,
          healthCareKu: this.state.data.healthCareKu,
          addressEn: this.state.data.addressEn,
          addressAr: this.state.data.addressAr,
          addressKu: this.state.data.addressKu,
          location: this.state.data.location,
          latitude: this.state.data.latitude,
          longitude: this.state.data.longitude,
          appoCost: this.state.data.appoCost,
          isActive: this.state.data.isActive,
          appSysFee: this.state.data.appSysFee,
          maxDailyAppo: this.state.data.maxDailyAppo,
          email: this.state.data.email,
          gender: this.state.data.gender,
          birthdate: this.state.data.birthdate,
          appNumDaysAhead: this.state.data.appNumDaysAhead,
        })
      );
    } else {
      data.append(
        "requestData",
        JSON.stringify({
          nameEn: this.state.data.nameEn,
          nameAr: this.state.data.nameAr,
          nameKu: this.state.data.nameKu,
          specId: this.state.data.specId,
          aboutEn: this.state.data.aboutEn,
          aboutAr: this.state.data.aboutAr,
          aboutKu: this.state.data.aboutKu,
          healthCareEn: this.state.data.healthCareEn,
          healthCareAr: this.state.data.healthCareAr,
          healthCareKu: this.state.data.healthCareKu,
          phoneNum: this.state.data.phoneNum,
          addressEn: this.state.data.addressEn,
          addressAr: this.state.data.addressAr,
          addressKu: this.state.data.addressKu,
          location: this.state.data.location,
          latitude: this.state.data.latitude,
          longitude: this.state.data.longitude,
          appoCost: this.state.data.appoCost,
          isActive: this.state.data.isActive,
          appSysFee: this.state.data.appSysFee,
          maxDailyAppo: this.state.data.maxDailyAppo,
          password: this.state.data.password,
          email: this.state.data.email,
          gender: this.state.data.gender,
          birthdate: this.state.data.birthdate,
          appNumDaysAhead: this.state.data.appNumDaysAhead,
        })
      );
    }

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      data,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
      }
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CAF8"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary flex-item-left mr-2"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={false}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="الغاء"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "nameEn",
                label: "الاسم بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "nameAr",
                label: "الاسم بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "nameKu",
                label: "الاسم بالكردية",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "specId",
                label: "الاختصاص",
                options: this.state.allSpecs,
                disabled: false,
              },
              {
                type: "text",
                id: "phoneNum",
                label: "رقم الهاتف",
                disabled: this.state.inEditMode ? true : false,
              },
              {
                type: "text",
                id: "password",
                label: "كلمة المرور",
                disabled: this.state.inEditMode ? true : false,
              },
              {
                type: "text",
                id: "birthdate",
                label: "تاريخ الميلاد",
                disabled: false,
              },
              {
                type: "text",
                id: "email",
                label: "البريد الالكتروني",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "gender",
                label: "الجنس",
                disabled: false,
                options: this.state.allGender,
              },
              {
                type: "text",
                id: "aboutEn",
                label: "عن الطبيب بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "aboutAr",
                label: "عن الطبيب بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "aboutKu",
                label: "عن الطبيب بالكردية",
                disabled: false,
              },
              {
                type: "text",
                id: "healthCareEn",
                label: "الرعاية الصحية بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "healthCareAr",
                label: "الرعاية الصحية بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "healthCareKu",
                label: "الرعاية الصحية بالكردية",
                disabled: false,
              },
              {
                type: "text",
                id: "addressEn",
                label: "العنوان بالانكليزية",
                disabled: false,
              },
              {
                type: "text",
                id: "addressAr",
                label: "العنوان بالعربية",
                disabled: false,
              },
              {
                type: "text",
                id: "addressKu",
                label: "العنوان بالكردية",
                disabled: false,
              },
              {
                type: "text",
                id: "location",
                label: "الموقع",
                disabled: false,
              },
              {
                type: "text",
                id: "latitude",
                label: "latitude",
                disabled: false,
              },
              {
                type: "text",
                id: "longitude",
                label: "longitude",
                disabled: false,
              },
              {
                type: "text",
                id: "appoCost",
                label: "سعر الكشفية",
                disabled: false,
              },
              {
                type: "text",
                id: "appSysFee",
                label: "عمولة النظام",
                disabled: false,
                tooltip: "عمولة النظام من مبلغ الكشفية",
              },
              {
                type: "text",
                id: "maxDailyAppo",
                label: "عدد الحجوزات اليومي",
                disabled: false,
                tooltip: "الحد الاقصى للحجوزات اليومية للطبيب",
              },
              {
                type: "text",
                id: "appNumDaysAhead",
                label: "عدد الأيام المقبلة للحجز",
                disabled: false,
                tooltip: "عدد الأيام المقبلة للحجز",
              },
              {
                type: "dropdown",
                id: "isActive",
                label: "الحالة",
                options: this.state.allStatus,
                disabled: false,
              },
              {
                type: "fileupload",
                id: "file",
                label: "صورة الطبيب",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          isHeaderSticky={true}
          isVirtualScroll={true}
          texts={{
            search: "بحث:",
            totalRows: "إجمالي عدد البيانات:",
            rows: "الصف:",
            selected: "المحدد",
            rowsPerPage: "عدد الصفوف في الصفحة:",
            page: "الصفحة:",
            of: "من",
            prev: "السابق",
            next: "التالي",
            columnVisibility: "اظهار الاعمدة",
          }}
        />
      </div>
    );
  }
}

export default Doctors;
