import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./home";
import ChangePassword from "./changePassword";
import Notfound from "./notfound";
import ApplicationInfo from "../mainComponents/applicationInfo";
import UserManagement from "../contentComponents/system/usermanagement";
import RoleManagement from "../contentComponents/system/rolemanagement";
import SystemParameters from "../contentComponents/system/systemparameters";
import AppUsers from "../contentComponents/system/appusers";
import sysJwt from "../../utils/sysJwt";
import Reports from "../contentComponents/reports/reports";
import ExecuteReport from "../contentComponents/reports/executeReport";
import DrSpec from "../contentComponents/definitions/drspec";
import Doctors from "../contentComponents/definitions/doctors";
import DrTime from "../contentComponents/definitions/drtime";
import DrServices from "../contentComponents/definitions/drservices";
import Slider from "../contentComponents/definitions/slider";
import Faq from "../contentComponents/definitions/faq";
import Appointments from "../contentComponents/appointment/appointments";
import UnsettledAppointments from "../contentComponents/settlement/unsettledAppointments";
import UnsettledCash from "../contentComponents/settlement/unsettledCash";
import UnsettledVisaMc from "../contentComponents/settlement/unsettledVisaMc";
import SettledAppointments from "../contentComponents/settlement/settledAppointments";
import SettledCash from "../contentComponents/settlement/settledCash";
import SettledVisaMc from "../contentComponents/settlement/settledVisaMc";
import Notification from "../contentComponents/definitions/notifications";

class AppRoutes extends Component {
  state = {};
  render() {
    return (
      <Routes>
        {sysJwt.isAuthorized(["CAEC"]) && (
          <Route
            path="/reports"
            element={
              <Reports
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAEC", "CAF0"]) && (
          <Route
            path="/executereport"
            element={
              <ExecuteReport
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CABE"]) && (
          <Route
            path="/usermanagement"
            element={
              <UserManagement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAC5"]) && (
          <Route
            path="/rolemanagement"
            element={
              <RoleManagement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAD5"]) && (
          <Route
            path="/systemparameters"
            element={
              <SystemParameters
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF3"]) && (
          <Route
            path="/drspec"
            element={
              <DrSpec
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF7"]) && (
          <Route
            path="/doctors"
            element={
              <Doctors
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF7"]) && (
          <Route
            path="/drservices"
            element={
              <DrServices
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF7"]) && (
          <Route
            path="/drtime"
            element={
              <DrTime
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAFE"]) && (
          <Route
            path="/slider"
            element={
              <Slider
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB01"]) && (
          <Route
            path="/appusers"
            element={
              <AppUsers
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB04"]) && (
          <Route
            path="/faq"
            element={
              <Faq
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB08"]) && (
          <Route
            path="/appointments"
            element={
              <Appointments
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0B"]) && (
          <Route
            path="/unsettledappos"
            element={
              <UnsettledAppointments
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0B"]) && (
          <Route
            path="/unsettledcash"
            element={
              <UnsettledCash
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0B"]) && (
          <Route
            path="/unsettledvisamc"
            element={
              <UnsettledVisaMc
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0F"]) && (
          <Route
            path="/settledappos"
            element={
              <SettledAppointments
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0F"]) && (
          <Route
            path="/settledcash"
            element={
              <SettledCash
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0F"]) && (
          <Route
            path="/settledvisamc"
            element={
              <SettledVisaMc
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB10"]) && (
          <Route
            path="/notification"
            element={
              <Notification
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        <Route
          path="/applicationinfo"
          element={
            <ApplicationInfo
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />

        <Route
          path="/changepassword"
          element={
            <ChangePassword
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        <Route path="/home" element={<Home />} />
        <Route path="/notfound" element={<Notfound />} />
        <Route
          path="*"
          element={<Navigate to="/dashboard/notfound" replace />}
        />
      </Routes>
    );
  }
}

export default AppRoutes;
